html {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

body {
    flex-grow: 1;
    margin: 0;
    height: 100%;
    background-color: #141617;
    color: #fff;
    display: flex;
}

#container {
    display: flex;
    position: relative;
    /* height: 100%; */
}

.background-canvas {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
    filter: blur(30px);
}

.background-image {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('./bg2.png');
    opacity: 0.15;
    background-position-x: right;
    background-size: 300vh;
}

.background-overlay {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.5); */
    background: rgba(0, 0, 0, 0.8);
}

.preview-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preview-canvas {
    position: sticky;
    top: 20px;
    z-index: 1;

    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 0 15px rgb(0 0 0 / 20%);
    margin: auto;
    margin-top: 20px;
    width: 320px !important;
    height: 320px !important;
}

.snapshots {
    position: sticky;
    top: 350px;
    z-index: 2;

    display: flex;
    flex-direction: row;
    max-width: 320px;
    overflow: hidden;
    border-radius: 16px;
    margin-top: 8px;
}

.snapshot-canvas {
    width: 80px !important;
    height: 80px !important;
    position: relative;
}

.sliders-left {
    position: relative;
    /* display: flex; */
    /* flex-direction: column; */
    margin: 20px;
    width: 100%;
    text-align: center;
    padding: 0px 20px;
    box-sizing: border-box;
}

.slider-label {
    display: flex;
    flex-direction: column;
    margin: 10px;
    float: left;
}

.slider-label button {
    width: 30px;
    height: 30px;
    margin: 4px;
    margin-bottom: 0px;
    border-radius: 8px;
    font-weight: bold;
}

.slider-label input {
    vertical-align: middle;
}

.sliders-right {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.algo-select {
    max-width: 200px;
    min-width: 150px;
    /* float: left; */
    position: relative;
    margin-top: 10px;
}